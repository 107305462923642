<template>
  <div id="cpsForm" class="bankForm_wrapper">
    <el-form label-position="top" :model="form" ref="withdrawFormChild">
      <el-row v-if="ibtChannelsOnly" class="mb-3">
        <el-col :xs="24" :sm="11" class="label_wrapper">
          <el-form-item :label="$t('withdraw.default.selectBA')" prop="selectedCardID">
            <el-select v-model="form.selectedCardID" data-testid="selectedCardID">
              <el-option
                v-for="item in availableCards"
                :key="item.id"
                :value="item.id"
                :label="item.id | cardInfoDropdown(item, 2, dropdownTranslationObj)"
                :data-testid="`select_${item.id}`"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="40" v-if="form.selectedCardID || form.selectedCardID === 0">
        <el-col :xs="24" :sm="12" v-for="(type, index) in form.attachVariables" :key="index">
          <!-- Input Field -->
          <DynamicRestrictInput
            :label="getFieldLabel(type.field_name)"
            :name="'attachVariables.' + index + '.value'"
            :testId="getTestId(type.key, type.field_type)"
            :restrictionType="type.restriction_type"
            v-model="form.attachVariables[index].value"
            :maxlength="type.field_length"
            :disabled="isdisabled"
            v-if="isInputField(type.field_type)"
          ></DynamicRestrictInput>
          <!-- Dropdown List -->
          <el-form-item
            v-if="type.field_type == 'dropdown_list' && type.type === 'bank'"
            :label="$t('common.field.bankName')"
            :prop="'attachVariables.' + index + '.value'"
            :rules="selectRules"
          >
            <el-select
              v-model="form.attachVariables[index].value"
              :placeholder="$t('common.field.select')"
              :data-testid="getTestId(type.key, type.field_type)"
              :disabled="isdisabled"
            >
              <el-option
                :label="value.bank_name"
                :value="value.bank_code"
                v-for="(value, name) in type.field_content"
                :key="name"
                :data-testid="`${getTestId(type.key, type.field_type)}_${value}`"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item
            v-if="type.field_type == 'dropdown_list' && type.type !== 'bank'"
            :label="getFieldLabel(type.field_name)"
            :prop="'attachVariables.' + index + '.value'"
            :rules="selectRules"
          >
            <el-select
              v-model="form.attachVariables[index].value"
              :placeholder="$t('common.field.select')"
              :data-testid="getTestId(type.key, type.field_type)"
              :disabled="isdisabled"
              @change="clearSubFieldValue(index, type.subBranchLength)"
            >
              <el-option
                :label="getFieldLabel(value.desc)"
                :value="value.value"
                v-for="(value, name) in type.field_content"
                :key="name"
                :data-testid="`${getTestId(type.key, type.field_type)}_${value}`"
              ></el-option>
            </el-select>
          </el-form-item>
          <!-- Dropdown Sub List -->
          <el-form-item
            v-if="
              type.field_type == 'dropdown_list_sub' &&
                form.attachVariables[index - type.siblingIndex].value === type.dependCode
            "
            :label="getFieldLabel(type.field_name)"
            :prop="'attachVariables.' + index + '.value'"
            :rules="selectRules"
            :class="type.type == 'branch' && withdrawalType == 62 ? 'mb-5' : ''"
          >
            <el-select
              v-model="form.attachVariables[index].value"
              :placeholder="$t('common.field.select')"
              :disabled="isdisabled"
              :data-testid="getTestId(type.key, type.field_type)"
            >
              <el-option
                :label="value.desc"
                :value="value.value"
                v-for="(value, i) in type.field_content"
                :key="i"
                :data-testid="`${getTestId(type.key, type.field_type)}_${value}`"
              ></el-option>
            </el-select>
          </el-form-item>

          <!-- Input Field Sub -->
          <el-form-item
            v-if="
              (type.field_type == 'input_field_sub' || type.field_type == 'email_sub') &&
                form.attachVariables[index - type.siblingIndex].value === type.dependCode
            "
            :label="getFieldLabel(type.field_name)"
            :key="index"
            :prop="'attachVariables.' + index + '.value'"
            :rules="inputRules"
            class="showPlaceholder"
          >
            <el-input
              :data-testid="getTestId(type.key, type.field_type)"
              v-model="form.attachVariables[index].value"
              :disabled="isdisabled"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12">
          <!-- Important Notes -->
          <el-form-item :label="$t('common.field.imptNotes')" prop="importantNotes">
            <ImportantNotesInput v-model="form.importantNotes" data-testid="importantNotes"></ImportantNotesInput>
          </el-form-item>
        </el-col>
      </el-row>

      <div class="checkbox_wrapper" v-if="!form.userPaymentInfoId && ibtChannelsOnly && form.selectedCardID == -1">
        <el-checkbox v-model="isRememberInfo" data-testid="checkbox">
          {{ $t('withdraw.default.remember') }}</el-checkbox
        >
      </div>
      <div class="alert-info" v-if="showFee">
        {{ commissionsText }}
      </div>
    </el-form>
  </div>
</template>

<script>
import withdrawlMixin from '@/mixins/withdrawl/withdrawl';
import bankTransferMixin from '@/mixins/withdrawl/bankTransfer';
import { validateEmail } from '@/util/validators';
import fieldLabelList from '@/constants/withdrawFieldLabel';
import DynamicRestrictInput from '@/components/form/DynamicRestrictInput';
import ImportantNotesInput from '@/components/form/ImportantNotesInput';

export default {
  name: 'CpsWithdraw',
  components: { DynamicRestrictInput, ImportantNotesInput },
  props: {
    withdrawalType: Number,
    percentageFee: Number,
    showFee: Boolean
  },
  mixins: [bankTransferMixin, withdrawlMixin],
  data() {
    return {
      form: {
        type: 'cps',
        cpsType: true,
        bankName: '',
        bankProvince: '',
        bankCity: '',
        bankAccountName: '',
        bankBranchName: '',
        accountNumber: '',
        importantNotes: '',
        userPaymentInfoId: null,
        attachVariables: [],
        specialVariables: [],
        finalAttachVariables: {},
        selectedCardID: null
      },
      isRememberInfo: true,
      selectedCardInfoID: '',
      ibtChannels: [
        5,
        6,
        8,
        9,
        24,
        35,
        39,
        40,
        42,
        47,
        50,
        51,
        52,
        53,
        54,
        55,
        56,
        57,
        58,
        62,
        63,
        64,
        67,
        100,
        102,
        108,
        110
      ],
      selectRules: {
        required: true,
        message: this.$t('common.formValidation.common'),
        trigger: 'change'
      },
      emailRules: [
        {
          required: true,
          message: this.$t('common.formValidation.dynamicReq', {
            dynamic: this.$t('register.personalDetails.page1.email')
          }),
          trigger: 'blur'
        },
        {
          validator: validateEmail(),
          trigger: 'blur'
        }
      ],
      inputRules: {
        required: true,
        message: this.$t('common.formValidation.common'),
        trigger: 'blur'
      },
      commissions: {
        31: 'withdraw.SkrillChargeAlert',
        32: 'withdraw.NetellerChargeAlert'
      }
    };
  },
  methods: {
    getTestId(val, type) {
      switch (val) {
        case 'holderAddress':
          return 'bankAddress';
        case 'beneficiaryName':
          return 'beneficiaryName';
        case 'bank_branch':
          if (type === 'dropdown_list_sub') {
            return 'selectedBankBranch';
          }
          return 'bankBranch';
        case 'card_name':
          return 'accountName';
        case 'card_number':
          return 'accountNumber';
        case 'city':
          return 'bankCity';
        case 'province':
          return 'bankProvince';
        case 'bank_code':
          return 'bankName';
        case 'bank_code':
          return 'bankName';
        case 'ifsc_code':
          return 'ifscCode';
        case 'attach_account_type':
          return 'accountType';
        default:
          return val;
      }
    },
    getFieldLabel(val) {
      const i18nKey = this.getI18nKey(val);
      if (this.$te(i18nKey) || this.$te(i18nKey, 'en_US')) {
        if (i18nKey === 'common.field.paymentAccName' || i18nKey === 'common.field.paymentAccNum')
          return this.$t(i18nKey, { name: val.split(' ')[0] });
        return this.$t(i18nKey);
      }
      return val;
    },
    getI18nKey(val) {
      let label = fieldLabelList[val];
      return label ? label : val;
    },
    reconfigureAttachVariables() {
      this.form.attachVariables = JSON.parse(this.$store.state.cps.withdrawalPayment.merchant_variable)?.customer_input;

      // filter by attach variables key in object
      let attachVariables = Object.values(JSON.parse(this.$store.state.cps.withdrawalPayment.attach_variable));
      this.form.specialVariables = [];
      attachVariables.forEach(f => {
        if (f.hasOwnProperty('field_type')) {
          this.form.specialVariables.push(f);
          if (f.field_type === 'dropdown_list') this.createSubField(f);
        }
      });
      if (this.form.specialVariables.length > 0) {
        this.form.attachVariables = [...this.form.specialVariables, ...this.form.attachVariables];
      }
    },
    createSubField(field) {
      field.field_content.forEach((fc, i) => {
        if (fc.field_type) {
          field.subBranchLength = field.subBranchLength ? field.subBranchLength + 1 : 1;
          this.form.specialVariables.push({
            field_type: fc.field_type + '_sub',
            field_name: fc.field_name,
            type: fc.type,
            key: fc.key,
            dependKey: field.key,
            dependCode: fc.bank_code,
            siblingIndex: i + 1,
            field_content: fc.field_content
          });
        }
      });
    },
    clearSubFieldValue(index, subBranchLength) {
      if (!subBranchLength) return;
      for (let i = index + 1; i <= subBranchLength; i++) {
        delete this.form.attachVariables[i].value;
      }
    },
    getFormData() {
      // get bank name index
      let bankNameIndex = this.form.attachVariables
        ? this.form.attachVariables.findIndex(function(type) {
            return type.key == 'bank_code' || type.key == 'bank_name';
          })
        : -1;

      // get bank account name index
      let bankAccNameIndex = this.form.attachVariables
        ? this.form.attachVariables.findIndex(function(type) {
            return type.key == 'card_name';
          })
        : -1;

      // get bank account number index
      let bankAccNumIndex = this.form.attachVariables
        ? this.form.attachVariables.findIndex(function(type) {
            return type.key == 'card_number';
          })
        : -1;

      // get bank city index
      let bankCityIndex = this.form.attachVariables
        ? this.form.attachVariables.findIndex(function(type) {
            return type.key == 'city';
          })
        : -1;

      // get bank province index
      let bankProvinceIndex = this.form.attachVariables
        ? this.form.attachVariables.findIndex(function(type) {
            return type.key == 'province';
          })
        : -1;

      // get bank branch index
      let bankBranchIndex = this.form.attachVariables
        ? this.form.attachVariables.findIndex(function(type) {
            return type.key == 'bank_branch' && type.value;
          })
        : -1;

      // get email index
      let emailIndex = this.form.attachVariables
        ? this.form.attachVariables.findIndex(function(type) {
            return type.key == 'mail';
          })
        : -1;

      let ifscCodeIndex = this.form.attachVariables
        ? this.form.attachVariables.findIndex(function(type) {
            return type.key == 'ifsc_code';
          })
        : -1;

      let bankAccTypeIndex = this.form.attachVariables
        ? this.form.attachVariables.findIndex(function(type) {
            return type.key == 'attach_account_type';
          })
        : -1;

      // get bankBranchCode index
      let bankBranchCodeIndex = this.form.attachVariables
        ? this.form.attachVariables.findIndex(function(type) {
            return type.key == 'branch_code' && type.value;
          })
        : -1;

      // get targetCurrency index
      let targetCurrencyIndex = this.form.attachVariables
        ? this.form.attachVariables.findIndex(function(type) {
            return type.key == 'bank_account_currency';
          })
        : -1;

      // get attachRegion index
      let attachRegionIndex = this.form.attachVariables
        ? this.form.attachVariables.findIndex(function(type) {
            return type.key == 'attach_region';
          })
        : -1;

      // get bank Address index
      let bankAddressIndex = this.form.attachVariables
        ? this.form.attachVariables.findIndex(function(type) {
            return type.key == 'bank_address';
          })
        : -1;

      // get swift index
      let swiftIndex = this.form.attachVariables
        ? this.form.attachVariables.findIndex(function(type) {
            return type.key == 'swift';
          })
        : -1;

      // get holder address index
      let holderAddressIndex = this.form.attachVariables
        ? this.form.attachVariables.findIndex(function(type) {
            return type.key == 'holder_address';
          })
        : -1;

      // map attach variables to accept only key & value format
      this.form.finalAttachVariables = {};
      this.form.attachVariables.map(a => {
        if (a.key != 'mail' && a.value) {
          this.form.finalAttachVariables[a.key] = a.value;
        }
      });

      return {
        withdrawalType: this.withdrawalType,
        bankAccType: bankAccTypeIndex != -1 ? this.form.attachVariables[bankAccTypeIndex].value : '',
        bankBranchCode: bankBranchCodeIndex != -1 ? this.form.attachVariables[bankBranchCodeIndex].value : '',
        bankName: bankNameIndex != -1 ? this.form.attachVariables[bankNameIndex].value : '',
        bankProvince: bankProvinceIndex != -1 ? this.form.attachVariables[bankProvinceIndex].value : '',
        bankCity: bankCityIndex != -1 ? this.form.attachVariables[bankCityIndex].value : '',
        bankAccountName: bankAccNameIndex != -1 ? this.form.attachVariables[bankAccNameIndex].value : '',
        bankBranchName: bankBranchIndex != -1 ? this.form.attachVariables[bankBranchIndex].value : '',
        accountNumber: bankAccNumIndex != -1 ? this.form.attachVariables[bankAccNumIndex].value : '',
        importantNotes: this.form.importantNotes,
        isRememberInfo: this.form.selectedCardID == -1 ? this.isRememberInfo : '',
        userPaymentInfoId: this.selectedCardInfoID ? this.selectedCardInfoID : '',
        orderCurrency: this.$store.state.cps.withdrawalPayment.actual_currency_number,
        paymentMethodCode: this.$store.state.cps.withdrawalPayment.payment_method,
        ebuyEmail: emailIndex != -1 ? this.form.attachVariables[emailIndex].value : '',
        attachVariables: JSON.stringify(this.form.finalAttachVariables),
        mandatory: JSON.parse(this.$store.state.cps.withdrawalPayment.attach_variable).mandatory.toString(),
        targetCurrency: targetCurrencyIndex != -1 ? this.form.attachVariables[targetCurrencyIndex].value : '',
        ifscCode: ifscCodeIndex != -1 ? this.form.attachVariables[ifscCodeIndex].value : '',
        attachRegion: attachRegionIndex != -1 ? this.form.attachVariables[attachRegionIndex].value : '',
        bankAddress: bankAddressIndex != -1 ? this.form.attachVariables[bankAddressIndex].value : '',
        swift: swiftIndex != -1 ? this.form.attachVariables[swiftIndex].value : '',
        holderAddress: holderAddressIndex != -1 ? this.form.attachVariables[holderAddressIndex].value : ''
      };
    },
    submitForm() {
      return this.$refs.withdrawFormChild.validate();
    },
    isInputField(fieldType) {
      const inputType = ['input_field', 'email'];
      return inputType.includes(fieldType);
    }
  },
  computed: {
    ibtChannelsOnly() {
      if (this.ibtChannels.includes(this.withdrawalType)) {
        return true;
      }
      return false;
    },
    commissionsText() {
      return this.$t(this.commissions[this.withdrawalType], { percentageFee: this.percentageFee });
    }
  },
  watch: {
    form: {
      handler(val, oldVal) {
        this.$emit('formChange', val);
      },
      deep: true
    },
    'form.selectedCardID'(value) {
      const selectedCardInfo = this.availableCards.find(item => item.id === value);
      const isCurrentCard = selectedCardInfo && selectedCardInfo.id !== -1;
      this.selectedCardInfoID = isCurrentCard ? selectedCardInfo.id : '';

      if (isCurrentCard) {
        this.$nextTick(() => {
          this.reconfigureAttachVariables();

          this.form.attachVariables.forEach(a => {
            if (a.key == 'bank_code' || a.key == 'bank_name') {
              a.value = selectedCardInfo.bankName;
            }
            if (a.key == 'card_number') {
              a.value = selectedCardInfo.accountNumber;
            }
            if (a.key == 'card_name') {
              a.value = selectedCardInfo.bankAccountName;
            }
            if (a.key == 'bank_branch') {
              a.value = selectedCardInfo.bankBranchName;
            }
            if (a.key == 'city') {
              a.value = selectedCardInfo.bankCity;
            }
            if (a.key == 'province') {
              a.value = selectedCardInfo.bankProvince;
            }
            if (a.key == 'ifsc_code') {
              a.value = selectedCardInfo.ifscCode;
            }
            if (a.key == 'branch_code') {
              a.value = selectedCardInfo.bankBranchCode;
            }
            if (a.key == 'attach_account_type') {
              a.value = selectedCardInfo.accountType;
            }
            if (a.key == 'bank_account_currency') {
              a.value = selectedCardInfo.targetCurrency;
            }
            if (a.key == 'attach_document_type') {
              a.value = selectedCardInfo.attachDocumentType;
            }
            if (a.key == 'attach_account_digit') {
              a.value = selectedCardInfo.accountDigit;
            }
            if (a.key == 'personal_id') {
              a.value = selectedCardInfo.personalId;
            }
            if (a.key == 'swift') {
              a.value = selectedCardInfo.swift;
            }
            if (a.key == 'attach_region') {
              a.value = selectedCardInfo.attachRegion;
            }
            if (a.key == 'bank_address') {
              a.value = selectedCardInfo.bankAddress;
            }
          });
        });
      } else {
        this.$nextTick(() => {
          this.reconfigureAttachVariables();
        });
      }

      this.isdisabled = isCurrentCard ? true : false;
    },
    'form.accountNumber'(value) {
      this.form.accountNumber = this.latinNumberValidator(value);
    },
    'form.beneficiaryName'(value) {
      this.form.beneficiaryName = this.latinNumberValidator(value);
    },
    withdrawalType: {
      immediate: true,
      handler(val) {
        if (this.ibtChannels.includes(val)) {
          this.form.selectedCardID = null;
        } else {
          this.form.selectedCardID = 0;
        }
        this.reconfigureAttachVariables();
      }
    }
  }
};
</script>
